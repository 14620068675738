import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import * as pickDropConfig from '../config/city.json';
import { useEffect, useState } from 'react';
import Select from "react-dropdown-select";
import getCars from '../config/cars';
import * as _ from 'lodash';
import { SuperSEO } from "react-super-seo";

function Index() {
    const [cities, setCities] = useState<any[]>([]),
        [selectedCity, setSelectedCity] = useState<any>(''),
        [pickupPoints, setPickupPoints] = useState<any[]>([]),
        [dropoffPoints, setDropoffPoints] = useState<any[]>([]),
        [selectedService, setSelectedService] = useState<string>('self driving car rental');

    useEffect(() => {
        window.scrollTo(0, 0)
        let cities = Object.keys(pickDropConfig);
        let selectCities: any[] = [];
        cities.forEach((city, index) => {
            if (city != 'default')
                selectCities.push({ id: index, name: city })
        })
        setCities(selectCities);
    }, [])

    const getCharges = (vehicle: any) => {
        return vehicle.Charges.length > 1 ? `${vehicle.Charges[0].toString()}*` : vehicle.Charges[0].toString()
    }
    const getKMs = (vehicle: any) => {
        return vehicle['KM Included'].length > 1 ? `${vehicle['KM Included'][0]}*` : vehicle['KM Included'][0];
    }
    const getExtraKMCharges = (vehicle: any) => {
        return vehicle['Extra KM Charges'].length > 1 ? `${vehicle['Extra KM Charges'][0]}*` : vehicle['Extra KM Charges'][0]
    }

    function onCitySelected(city: any) {
        let pickupPoints: any[] = [], dropoffPoints: any[] = [];
        pickDropConfig[city as keyof typeof pickDropConfig].pickup.forEach((pickup, index) => {
            pickupPoints.push({ id: index, name: pickup })
        });
        pickDropConfig[city as keyof typeof pickDropConfig].dropoff.forEach((pickup, index) => {
            dropoffPoints.push({ id: index, name: pickup })
        });
        setPickupPoints(pickupPoints);
        setDropoffPoints(dropoffPoints);
    }
    return (
        <>
            <SuperSEO
                title="Aapli Gaadi: Best Car Rental platform to Explore the Unexplored"
                description="Aapli Gaadi is a marketplace for cars on rent originated from the Konkan region of Maharashtra. From short road trips to quick near-city drives for supply pick-up, and regional food runs, we have the cheapest car rental options for all your needs!"
                lang="en"
            />
            <div className="hero-wrap " style={{ backgroundImage: `url('/image/bg_1.jpg')` }} data-stellar-background-ratio="0.5">

                <div className="container">
                    <div className="row no-gutters slider-text justify-content-start align-items-center justify-content-center">
                        <div className="col-lg-8 ">
                            <div className="text w-100 text-center mb-md-5 pb-md-5">
                                <h1 className="mb-4">Fast &amp; Easy Way To Rent A Car</h1>
                                <p style={{ fontSize: '18px' }}>
                                    Drive flexible kilometers starting as low as 799/day onwards with <a href='http://www.aapligaadi.com' target={'_self'}>Aapli Gaadi</a>
                                </p>
                                {/* <a href="https://vimeo.com/45830194" className="icon-wrap popup-vimeo d-flex align-items-center mt-4 justify-content-center">
                                    <div className="icon d-flex align-items-center justify-content-center">
                                        <span className="ion-ios-play"></span>
                                    </div>
                                    <div className="heading-title ml-5">
                                        <span>Easy steps for renting a car</span>
                                    </div>
                                </a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="ftco-section ftco-no-pt bg-light">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-md-12	featured-top">
                            <div className="row no-gutters">
                                <div className="col-md-12 d-flex align-items-center" id="select-city">
                                    <div className="services-wrap rounded-right w-100">
                                        <h3 className="heading-section mb-4">Better Way to Rent Your Perfect Cars</h3>
                                        <div className="row d-flex mb-4">
                                            <div className="col-md-4 d-flex align-self-stretch ">
                                                <div className="services w-100 text-center">
                                                    <div className="icon d-flex align-items-center justify-content-center"><span className="flaticon-route"></span></div>
                                                    <div className="text w-100">
                                                        <h3 className="heading mb-2">Choose Your Pickup Location</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 d-flex align-self-stretch ">
                                                <div className="services w-100 text-center">
                                                    <div className="icon d-flex align-items-center justify-content-center"><span className="flaticon-handshake"></span></div>
                                                    <div className="text w-100">
                                                        <h3 className="heading mb-2">Select the Best Deal</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 d-flex align-self-stretch ">
                                                <div className="services w-100 text-center">
                                                    <div className="icon d-flex align-items-center justify-content-center"><span className="flaticon-rent"></span></div>
                                                    <div className="text w-100">
                                                        <h3 className="heading mb-2">Reserve Your Rental Car</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col-md-12 mb-3 d-flex align-items-center justify-content-center ' onChange={(e) => { setSelectedService((e.target as HTMLInputElement).defaultValue) }} >
                                                <input type={'radio'} value="self driving car rental" name="service_type" checked={selectedService === 'self driving car rental'} onChange={() => { }}></input>&nbsp;&nbsp; Self Drive &nbsp;&nbsp;&nbsp;&nbsp;
                                                <input type={'radio'} value="car rental" name="service_type" checked={selectedService === 'car rental'} onChange={() => { }}></input>&nbsp;&nbsp; With Driver &nbsp;&nbsp;&nbsp;&nbsp;
                                                <br />
                                            </div>
                                            <div className='col-md-2'>
                                            </div>
                                            <div className="col-md-4">
                                                <Select values={[]} labelField='name' searchable={true} valueField='name' options={cities} onChange={(values) => setSelectedCity(values[0].name)} />
                                            </div>
                                            <div className='col-mg-4' style={{ paddingLeft: '25px' }}>
                                                <Link to={selectedCity ? `/services/${selectedService.toLowerCase().replaceAll(' ', '-')}/${selectedCity.toLowerCase().replaceAll(' ', '-')}` : '#'} className="btn btn-primary" >Reserve Your Perfect Car</Link>
                                            </div>
                                            <div className='col-md-2'>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="ftco-section ftco-no-pt bg-light">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 heading-section text-center  mb-5">
                            <span className="subheading">What we offer</span>
                            <h2 className="mb-2">Vehicles Offered</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <OwlCarousel className="carousel-car owl-carousel" autoPlay={true} autoplayTimeout={2000} items={3} responsive={{ 0: { items: 1 }, 450: { items: 2 }, 600: { items: 3 }, 1000: { items: 3 } }}>
                                {
                                    getCars().map((vehicle: any, idx: number) => <div className="item" style={{ padding: '2%' }}>
                                        <div className="car-wrap rounded " key={idx}>
                                            <div className="img rounded d-flex align-items-end" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}'/image/cars/${vehicle.Image}')` }}>
                                            </div>
                                            <div className="text">
                                                <h2 className="mb-0"><a href="#">{vehicle.Name}</a></h2>
                                                <span>{vehicle.Details.Fuel}</span>
                                                <div className="d-flex">
                                                    <span className="cat">{vehicle.Brand}</span>
                                                    <p className="price ml-auto">{getCharges(vehicle)} <span>/{vehicle.UOM}</span></p>
                                                </div>
                                                <div className="d-flex mb-3">
                                                    <span className="cat">{getKMs(vehicle)} KMs</span>
                                                    <p className="price ml-auto"><span>Extra Kilometer Charges: {getKMs(vehicle) == 'Unlimited' ? 'N/A' : `${getExtraKMCharges(vehicle)} / KM`}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                                }

                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ftco-section ftco-about">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-md-6 p-md-5 img img-2 d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}'/image/car-rental.jpeg')` }}>
                        </div>
                        <div className="col-md-6 wrap-about ">
                            <div className="heading-section heading-section-white pl-md-5">
                                <span className="subheading">About us</span>
                                <h2 className="mb-4">Welcome to <a href='http://www.aapligaadi.com' target={'_self'}>Aapli Gaadi</a></h2>

                                <p> <a href='http://www.aapligaadi.com' target={'_self'}>Aapli Gaadi</a>  is a marketplace for cars on rent originated from Konkan region of Maharashtra. From short road trips to quick near city drives for supply pick-up, regional food runs, we have the cheapest car rental options for all your needs!</p>
                                <p>An economical hatchback for narrow roads, a sedan for short trips, SUV for hills and all for exploring the unexplored Konkan Coastal Region.</p>
                                <p>With  <a href='http://www.aapligaadi.com' target={'_self'}>Aapli Gaadi</a> , you can experience the convenience of online booking. The cars listed on our platform come with all-India permits that include vehicle insurance. It is extremely easy to pick up the car from the host location. You can select from flexible KMs, with 100% Free Cancellation up to 6 hours before the trip start, Minimum Security Deposit, and 24/7 Roadside Assistance. Car rent per KM starts as low as Rs. 1200/day. From short road trips to quick in-city drives for groceries, supply pick-up, meeting friends and family, doctor visits, business trips, we have the cheapest car rental options for all your needs! </p>
                                <p><a href='#select-city' className="btn btn-primary py-3 px-4">Search AapliGaadi</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ftco-section">
                <div className="container">
                    <div className="row justify-content-center mb-5">
                        <div className="col-md-7 text-center heading-section ">
                            <span className="subheading">Services</span>
                            <h2 className="mb-3">Our Latest Services</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="services services-2 w-100 text-center">
                                <div className="icon d-flex align-items-center justify-content-center"><span className="flaticon-wedding-car"></span></div>
                                <div className="text w-100">
                                    <h3 className="heading mb-2">Self Drive Rental</h3>
                                    <p>Are you holding license for atleast 2 years? Great! Now you can rent a car with <a href='http://www.aapligaadi.com' target={'_self'}>Aapli Gaadi</a> and enjoy your vacations!</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="services services-2 w-100 text-center">
                                <div className="icon d-flex align-items-center justify-content-center"><span className="flaticon-transportation"></span></div>
                                <div className="text w-100">
                                    <h3 className="heading mb-2">Pick and Drop Services</h3>
                                    <p>Want a solution for Airport/Railway station pick and drop? @<a href='http://www.aapligaadi.com' target={'_self'}>Aapli Gaadi</a>, we provide Airport / Railway station pick and drop services.<br />*Applicable only in selected cities</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="services services-2 w-100 text-center">
                                <div className="icon d-flex align-items-center justify-content-center"><span className="flaticon-transportation"></span></div>
                                <div className="text w-100">
                                    <h3 className="heading mb-2">Whole City Tour</h3>
                                    <p>Explore the beauty of Konkan with <a href='http://www.aapligaadi.com' target={'_self'}>Aapli Gaadi</a>.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ftco-section ftco-intro" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}'/image/driver.webp')` }}>
                <div className="overlay"></div>
                <div className="container">
                    <div className="row justify-content-end">
                        <div className="col-md-6 heading-section heading-section-white ">
                            <h2 className="mb-3">Do You Want To Earn With Us? So Don't Be Late.</h2>
                            <Link to={'#'} className="btn btn-primary btn-lg"> Join Us</Link>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ftco-section testimony-section bg-light">
                <div className="container">
                    <div className="row justify-content-center mb-5">
                        <div className="col-md-7 text-center heading-section ">
                            <span className="subheading">Benefits of choosing <a href='http://www.aapligaadi.com' target={'_self'}>Aapli Gaadi</a></span>
                            <h2 className="mb-3">Benefits</h2>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-12">
                            <OwlCarousel className="carousel-testimony owl-carousel ftco-owl" items={3} responsive={{ 0: { items: 1 }, 450: { items: 2 }, 600: { items: 3 }, 1000: { items: 3 } }}>
                                <div className="item" key={11}>
                                    <div className="testimony-wrap rounded text-center py-4 pb-5">
                                        <div className="text pt-4">
                                            <p className="name">Drive It Anywhere!</p>
                                            <p className="mb-4">
                                                <a href='http://www.aapligaadi.com' target={'_self'}>Aapli Gaadi</a> provides comfortable vehicles for you to take your friends and family on trip!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="item" key={12}>
                                    <div className="testimony-wrap rounded text-center py-4 pb-5">
                                        <div className="text pt-4">
                                            <p className="name">Low security deposite!</p>
                                            <p className="mb-4">
                                                We trust in our clients! Here at <a href='http://www.aapligaadi.com' target={'_self'}>Aapli Gaadi</a>, we provide vehicles with MINIMUM security deposites.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="item" key={13}>
                                    <div className="testimony-wrap rounded text-center py-4 pb-5">
                                        <div className="text pt-4">
                                            <p className="name">Flexible Kilometers</p>
                                            <p className="mb-4">All vehicles @ <a href='http://www.aapligaadi.com' target={'_self'}>Aapli Gaadi</a> comes with this feature of unilimited kilometers for daily self driving rental solutions.</p>
                                        </div>
                                    </div>
                                </div>
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Index